import React from 'react';
import { useTranslations } from 'use-intl';
import Cookies from 'cookies';
import { GetServerSidePropsContext } from 'next';
import { MainLayout } from '@/components/layout';
import { PageHead } from '@/components/common';
import { HomePageApi, TPageDataContent } from '@/shared/api/homepage-api';
import { PageMap } from '@/components/home';
import { mapSEOTags } from '@/shared/lib';
import {
  getRandomComponentIndex,
  withCreditLineTest,
} from '@/features/ab-testing';
import { TSEOComponentData } from '@/shared/types';

interface IPageHomeProps {
  messages: unknown;
  pageData: TPageDataContent[];
  pageDataABTesting: TPageDataContent[];
  seo: TSEOComponentData;
  abTestIndex?: number;
}

const PageHome = (props: IPageHomeProps) => {
  const { pageData, abTestIndex, seo, pageDataABTesting } = props;

  const seoTagsData = mapSEOTags(seo);
  const t = useTranslations();

  const isABTestingAvailable =
    pageDataABTesting &&
    Number.isInteger(abTestIndex) &&
    Number(abTestIndex) > -1;

  const pageContent = isABTestingAvailable
    ? [pageDataABTesting[Number(abTestIndex)], ...pageData]
    : pageData;

  return (
    <>
      <PageHead title={t('nav.home')} seo={seoTagsData} />
      <MainLayout>
        {pageContent?.map((content) =>
          content ? (
            <PageMap key={content.__component} content={content} />
          ) : null,
        )}
      </MainLayout>
    </>
  );
};

export default PageHome;

export const getServerSideProps = async ({
  req,
  res,
  locale,
  query,
}: GetServerSidePropsContext) => {
  const api = new HomePageApi(locale || 'en');
  const pageData = await api.getHomepageData();

  const cookies = new Cookies(req, res);

  const abTestContent = withCreditLineTest(
    pageData?.attributes?.ABtesting,
    cookies,
    query.utm_campaign,
  );

  const abTestIndex = getRandomComponentIndex(abTestContent, cookies);

  return {
    props: {
      messages: require(`../shared/lib/i18n/locales/${locale}.json`),
      pageData: pageData?.attributes?.content || null,
      pageDataABTesting: abTestContent,
      seo: pageData?.attributes?.seo || null,
      abTestIndex,
    },
  };
};
